<template>
    <a-modal v-model="visible" title="设备授权" :width="1000"
             okText="完成">
        <common-table
                ref="table"
                path="web/iot/device"
                search-placeholder="搜索"
                :columns="columns"
                >

            <template slot="search">

            </template>

            <template slot="icon" slot-scope="{record}">
<!--                <a-avatar shape="square" :src="record.devicePicture" style="background: #F8F8F8;margin-right: 10px"/>-->
                {{record.productName}}
            </template>
            <template slot="status" slot-scope="{record}">
                <a-tag v-if="!record.online" color="#D1D1D1">离线</a-tag>
                <a-tag v-else-if="record.alarm" color="#EEC900">报警</a-tag>
                <a-tag v-else color="#00ff99">在线</a-tag>
            </template>
            <template slot="operation" slot-scope="{record}">
                <a-button size="small" @click="clickAdd(record)">添加授权</a-button>
            </template>

        </common-table>
    </a-modal>
</template>

<script>
    export default {
        props:["vendorId"],
        data(){
            return {
                visible:false,
                productList:[]
            }
        },
        created() {
            this.$get('product').then((r) => {
                this.productList = r.data
            })
        },
        computed:{
            columns () {
                let productFilter = [{text: '全部', value: ''}]
                this.productList.forEach(item=>{
                    productFilter.push({text: item.name, value: item.productKey})
                })
                return [
                    {
                        title: '状态',
                        dataIndex: 'status',
                        scopedSlots: {customRender: 'status'},
                    },
                    {
                        title: '设备类型',
                        dataIndex: 'productKey',
                        scopedSlots: {customRender: 'icon'},
                        filters: productFilter,
                        filterMultiple: false,
                    },
                    {
                        title: '名称',
                        dataIndex: 'nickname',
                    },
                    {
                        title: '型号',
                        dataIndex: 'model'
                    },
                    {
                        title: '机身编码',
                        dataIndex: 'iotId'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        scopedSlots: {customRender: 'operation'},
                    }
                ]
            }
        },
        methods:{
            show(){
                this.visible=true
            },
            clickAdd(record){
                this.$post('vendor/device/add',{
                  vendorId:this.vendorId,
                  deviceId:record.id}).then(suc => {
                    this.$emit('success')
                    this.visible=false
                })

            }
        }
    }
</script>

<style scoped>

</style>
